@import "../../styles/vars.scss";

.introduction-view {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index:3;
    background-color:$clr-bg-introduction;

    &__full-width {
        max-width: 1280px;
        margin: 0 auto;
        width:100%;
        position: relative;
        height: 100%;

    }

    &__topbar {
        padding: 2em;
    }

    &__main {
        position: absolute;
        top: 104px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        color:$clr-introduction;
    }

    &__topline {
        color:$clr-primary;
        text-transform:uppercase;
    }

    &__left {
        width: 40%;
        position: absolute;
        top: 50%;
        transform:translate(0,-50%);
        padding: 2em;
    }

    &__right {
        right: 0px;
        position: absolute;
        top: 50%;
        transform:translate(0,-50%);
        width: 640px;
        height: 640px;
        background-image:url(./images/right.png);
        background-size:contain;
        background-position: center center;
        background-repeat:no-repeat;
    }

    &__logo {
        background-image:url(../../logo.png);
        height: 2.5em;
        background-size: auto 100%;
        background-position: left center;
        background-repeat:no-repeat;
    }

}