@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,600;1,500&family=Montserrat:wght@400;800&family=Roboto&display=swap');
@import "../../styles/vars.scss";


body {
  background:#000;
  font-family: 'Poppins', sans-serif;
}

.form-view__logo {
    background-image:url(../../logo.png);
    height: 2.5em;
    background-size: auto 100%;
    background-position: left center;
    background-repeat:no-repeat;
    margin-bottom: 2em;
}


.form-view__sidebar {
  width: 550px;
  position: absolute;
  top: 0%;
  bottom: 0px;  
  padding: 32px;
  background:#000;
  background:#FFF;


  .livewall {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 2em;

    a {
      display:block;
      height: 2em;
      margin-bottom: 1em;
      background-image:url(../../images/livewall.png);
      background-size: auto 100%;
      background-repeat:no-repeat;
      background-position: center right;
      width: 100%;
    }
  }

  input, select {
    padding: 1em;
    border-radius: 16px;
    display:block;
    width: 100%;
    color:#FFF;
    background: transparent ;
    border: 1px solid rgba(0,0,0,0.3);
    color:rgba(0,0,0,0.6);
  }

  .form-view__sidebar__form__field {
    margin-bottom: 1em;

    &__label {
        font-size: 0.8em;
      margin-bottom: 0.5em;
      font-weight:800;
      color:#333;
    }
  }

  .form-view__sidebar__error {
    color:#b54b7e;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .form-view__sidebar__form__field__hosts {
    display:flex;
  }
  .form-view__sidebar__form__field__hosts__host {
    width: 100px;
    border-radius: 2em;
    padding: 8px;
    text-align:center;
    font-weight:bold;
    font-size: 0.8em;

    &__image {
      width: 6em;
      height: 6em;
      border-radius: 100%;
      background-size:cover;
      margin-left: auto;
      margin-right:auto;
      border: 2px solid transparent;
      cursor:pointer;
      margin-bottom: 1em;

      &.image1 {
        background-image: url(./images/host_zoe.jpg);
      }
      &.image2 {
        background-image: url(./images/host_mike.jpg);
      }
      &.image3 {
        background-image: url(./images/host_david.jpg);
      }
      &.image4 {
        background-image: url(./images/host_marc.jpg);
      }

    }

    &--active {
      .image1, .image2,.image3,.image4 {
        border: 2px solid $clr-primary;
      }
    }
  }


}

.form-view__video {
  position: absolute;
  left: 600px;
  right: 50px;
  top: 50%;
  transform:translate(0,-50%);

  &__holder {
    padding-bottom: 56.25%;
    position: relative;
  }

  video {
    position: absolute;
    top: 0px;
    left: 0px;
    display:block;
    background:#000;
    width: 100%;
    height: 100%;
  }

  &__status {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    color:#FFF;
    transform:translate(-50%,-50%);
    z-index:1;
    text-align:center;
    font-size: 18px;
  }

}

.form-view__sidebar__loading {
  width: 40px;
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.05); /* Border color of the spinner */
    border-top: 4px solid $clr-primary; /* Color of the spinning animation */
    border-radius: 50%; /* Makes the border circular */
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Apply the spinning animation */
    margin: 20px auto; /* Center the spinner */
  }
}

.form-view__sidebar__queue {
    animation: blinker 1s linear infinite;
    margin-bottom: 2em;
}

.form-view__sidebar__loading__bar {
  .progress-bar {
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 10px;
    overflow:hidden;
    
    &__fill {
      background:$clr-primary;
      font-size: 11px;
      padding: 3px;
      color:#FFF;
      line-height:1;
      text-align:right;
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); } /* Starting rotation */
  100% { transform: rotate(360deg); } /* Ending rotation after one full circle */
}


@keyframes blinker {
    50% {
      opacity: 0;
    }
  }